import { defineStore } from 'pinia'

interface Company {
  companyId: string
  logo: string
  name: string
}

export const useLandingStore = defineStore('landing', {
  state: () => ({
    type: 'institution',
    companies: [] as Company[]
  }),
  actions: {
    async getCompanies() {
      const { $Parse } = useNuxtApp()
      let pluginType
      let limit
      switch (this.type) {
        case 'institution':
          pluginType = 'InterestCompany'
          limit = '20'
          break
        case 'company':
          pluginType = 'CompanyIntro'
          limit = '50'
          break
        default:
      }
      this.companies = await $Parse.Cloud.run('GetClientsOfPlugin', {
        pluginType,
        limit
      })
    }
  }
})
